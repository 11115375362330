import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";

export const ContactMeDescription: React.FC = () => {
  const { colors, textStyles } = useTheme();

  return (
    <Flex flexDirection="column">
      <Text {...textStyles.h1} color={colors.text.primary}>
        Get in touch
      </Text>
      <Text {...textStyles.h2} color={colors.text.primary}>
        My inbox is always open.
      </Text>
      <Text {...textStyles.body} color={colors.text.secondary}>
        Check out my links below, or reach out to me directly via email!
      </Text>
    </Flex>
  );
};
