import React from "react";
import ReactDOM from "react-dom/client";

import {
  ChakraProvider,
  ColorModeContext,
  Flex,
  useTheme as useChakraTheme,
} from "@chakra-ui/react";
import { ScreenBlurContext, reportWebVitals } from "./utils";
import { BrowserRouter as Router } from "react-router-dom";
import { ContactMeScreen, NoiseBackground } from "./pages";
import { NavigationRouter } from "./routes";
import { neutralTheme, neutralThemeValues } from "./utils";
import { darkTheme } from "./utils";

export const useTheme = (): typeof neutralThemeValues => {
  return useChakraTheme();
};

declare global {
  interface Window {
    dataLayer: Array<Record<string, string>>;
  }
}

window.dataLayer = window.dataLayer || [];

function App() {
  const storedMode = localStorage.getItem("mode") as unknown as
    | "dark"
    | "neutral"
    | null;

  const [themeMode, setThemeMode] = React.useState<"dark" | "neutral">(
    storedMode ?? "dark"
  );
  const [isScreenBlurred, setIsScreenBlurred] = React.useState(false);

  const toggleDarkMode = () => {
    if (themeMode === "dark") {
      setThemeMode("neutral");
      localStorage.setItem("mode", "neutral");
    } else {
      setThemeMode("dark");
      localStorage.setItem("mode", "dark");
    }
  };

  const selectedTheme = themeMode === "dark" ? darkTheme : neutralTheme;

  return (
    <ChakraProvider theme={selectedTheme}>
      <ColorModeContext.Provider
        value={{
          toggleColorMode: toggleDarkMode,
          setColorMode: setThemeMode,
          colorMode: themeMode === "neutral" ? "light" : "dark",
        }}
      >
        <ScreenBlurContext.Provider
          value={{ isScreenBlurred, setIsScreenBlurred }}
        >
          <Router>
            <NoiseBackground />
            <ContactMeScreen />
            <Flex
              overflowY={{ base: "scroll", xl: "hidden" }}
              overflowX="clip"
              flex={1}
              width="100vw"
              height="100vh"
              filter={isScreenBlurred ? "blur(24px)" : undefined}
              pointerEvents={isScreenBlurred ? "none" : "auto"}
              transition="filter 0.5s ease"
            >
              <NavigationRouter />
            </Flex>
          </Router>
        </ScreenBlurContext.Provider>
      </ColorModeContext.Provider>
    </ChakraProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
