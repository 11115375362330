import { Flex } from "@chakra-ui/react";
import { LinkItem } from "../../../ui";

export const ContactMeLinks: React.FC = () => {
  return (
    <Flex flexDirection="column" gap="2">
      <LinkItem
        label="LinkedIn"
        onClick={() => {
          window.dataLayer.push({ event: "linkedInRedirect" });
          window.open("https://www.linkedin.com/in/luckywest/");
        }}
      />
      <LinkItem
        label="Github"
        onClick={() => {
          window.dataLayer.push({ event: "githubRedirect" });
          window.open("https://github.com/zelladays");
        }}
      />
      <LinkItem
        label="Email"
        onClick={() => {
          window.dataLayer.push({ event: "emailRedirect" });
          window.open("mailto:luckyjrwest@gmail.com");
        }}
      />
    </Flex>
  );
};
