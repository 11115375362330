import { Flex } from "@chakra-ui/react";
import React from "react";
import { HomeDescription, HomeLinks, HomeOverview } from "../components";
import { Divider, ScreenWrapper } from "../../ui";

export const HomeScreen = React.memo(() => {
  return (
    <ScreenWrapper isFirstScreen>
      <Flex flexDirection="column" gap="6">
        <HomeOverview />
        <HomeLinks />
        <Flex
          display={{ base: "flex", xl: "none" }}
          paddingTop="2"
          paddingBottom="6"
        >
          <Divider />
        </Flex>
      </Flex>
      <HomeDescription />
    </ScreenWrapper>
  );
});
