import { extendTheme } from "@chakra-ui/react";
import { textStyles } from "./textStyles";

const colors = {
  background: {
    primary: "#FFFFFF",
    secondary: "#FFFFFF",
  },

  text: {
    primary: "#000000",
    secondary: "#878787",
    tertiary: "#D72FA8",
  },
};

const neutralThemeValues = {
  colors,
  textStyles,
};

const neutralTheme = extendTheme({
  colors,
  textStyles,
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
});

export { neutralTheme, neutralThemeValues };
