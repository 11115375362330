import React from "react";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { LinkItem } from "../link-item";

const ROUTE_NAMES = {
  Back: "/",
};

export const BackNavLink = React.memo(() => {
  const [selectedItem, setSelectedItem] = React.useState<string>();
  const navigate = useNavigate();

  const onLinkClicked = React.useCallback((routeName: string) => {
    setSelectedItem(routeName);
    navigate(ROUTE_NAMES[routeName as keyof typeof ROUTE_NAMES]);
  }, []);

  return (
    <Flex flexDirection="column" gap="2">
      <LinkItem
        selected={selectedItem === "Back"}
        label="Back"
        onClick={onLinkClicked}
      />
    </Flex>
  );
});
