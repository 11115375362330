import React, { PropsWithChildren } from "react";
import { motion } from "framer-motion";

type TransitionWrapperProps = {
  isFirstScreen: boolean;
} & PropsWithChildren;

export const TransitionWrapper = React.memo(
  ({ children, isFirstScreen }: TransitionWrapperProps) => {
    return window.innerWidth >= 992 ? (
      <motion.div
        style={{ flex: 1, display: "flex" }}
        initial={{
          opacity: 0,
          scale: 0.8,
          rotateX: -60,
          rotateY: -60,
          translateX: isFirstScreen ? "100vw" : "-100vw",
          translateY: isFirstScreen ? "100vh" : "-100vh",
        }}
        animate={{
          opacity: 1,
          scale: 1,
          rotateX: 0,
          rotateY: 0,
          translateX: 0,
          translateY: 0,
          transition: {
            delay: 0.8,
            duration: 0.8,
            type: "spring",
            bounce: 0.25,
          },
        }}
        exit={{
          opacity: 0,
          scale: 0.8,
          rotateX: 60,
          rotateY: 60,
          translateX: isFirstScreen ? "100vw" : "-100vw",
          translateY: isFirstScreen ? "100vh" : "-100vh",
          transition: { duration: 0.8, type: "spring", bounce: 0.25 },
        }}
      >
        {children}
      </motion.div>
    ) : (
      <motion.div
        style={{ flex: 1, display: "flex" }}
        initial={{
          opacity: 0,
          translateX: isFirstScreen ? "100vw" : "-100vw",
        }}
        animate={{
          opacity: 1,
          translateX: 0,
          transition: {
            delay: 0.9,
            duration: 0.8,
            type: "spring",
            bounce: 0.25,
          },
        }}
        exit={{
          opacity: 0,
          translateX: isFirstScreen ? "100vw" : "-100vw",
          transition: { duration: 0.8, type: "spring", bounce: 0.25 },
        }}
      >
        {children}
      </motion.div>
    );
  }
);
