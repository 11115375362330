import React from "react";
import { useTheme } from "../../../..";
import { hexToRgba } from "../../../../utils";
import { Flex, Text } from "@chakra-ui/react";

export const Tag = React.memo(({ tag }: { tag: string }) => {
  const { textStyles, colors } = useTheme();

  return (
    <Flex
      backgroundColor={hexToRgba(colors.text.tertiary, 0.1)}
      borderRadius="12px"
      padding="2px 12px"
    >
      <Text {...textStyles.body__bold} color={colors.text.tertiary}>
        {tag}
      </Text>
    </Flex>
  );
});
