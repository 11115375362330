import React from "react";
import { BackNavLink, Divider, ScreenWrapper } from "../../ui";
import { Flex } from "@chakra-ui/react";
import { ProjectList, ProjectOverview } from "../components";

export const ProjectScreen = React.memo(() => {
  return (
    <ScreenWrapper isFirstScreen={false}>
      <Flex flexDirection="column" gap="6">
        <ProjectOverview />
        <BackNavLink />
        <Flex
          display={{ base: "flex", xl: "none" }}
          paddingTop="2"
          paddingBottom="6"
        >
          <Divider />
        </Flex>
      </Flex>
      <ProjectList />
    </ScreenWrapper>
  );
});
