import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";

export const HomeOverview = React.memo(() => {
  const { textStyles, colors } = useTheme();

  return (
    <Flex
      flexDirection="column"
      width={{ base: undefined, xl: "35vw", "2xl": "25vw" }}
    >
      <Text {...textStyles.h1} color={colors.text.primary}>
        Lucky West
      </Text>
      <Text {...textStyles.h2} color={colors.text.primary}>
        Front-End Developer
      </Text>
      <Text {...textStyles.body} color={colors.text.secondary}>
        Based in London, UK, I aspire to channel my creativity to produce clean
        software and engaging experiences.
      </Text>
    </Flex>
  );
});
