import { Flex } from "@chakra-ui/react";
import React from "react";

import { BackNavLink, Divider, ScreenWrapper } from "../../ui";
import { ExperienceOverview, ExperienceList } from "../components";

export const ExperienceScreen = React.memo(() => {
  return (
    <ScreenWrapper isFirstScreen={false}>
      <Flex flexDirection="column" gap="6">
        <ExperienceOverview />
        <BackNavLink />
        <Flex
          display={{ base: "flex", xl: "none" }}
          paddingTop="2"
          paddingBottom="6"
        >
          <Divider />
        </Flex>
      </Flex>
      <ExperienceList />
    </ScreenWrapper>
  );
});
