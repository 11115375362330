import { Flex } from "@chakra-ui/react";
import React from "react";
import { CardListItem } from "../../../ui";

export const ExperienceList = React.memo(() => {
  return (
    <Flex
      flexDirection="column"
      width={{ base: "90vw", xl: "45vw", "2xl": "35vw" }}
      maxHeight={{ base: undefined, xl: "50vh" }}
      overflowY={{ base: undefined, xl: "scroll" }}
      gap="8"
      borderRadius={{ base: undefined, xl: "32px" }}
    >
      <CardListItem
        date="September 2022 — Present"
        title="Software Engineer"
        subtitle="Tipalti"
        description={[
          "Deliver high-quality, robust production code for the front-end of an array of products spanning across the web and mobile.",
          "Led initiatives for domains in the mobile app and web, leading and contributing towards feature design and implementation, exporting packages for use cross-teams.",
          "Fully test code via unit, integration, and end-to-end tests on mobile and web to ensure product stability.",
        ]}
        link="https://tipalti.com/"
        tags={[
          "React",
          "React Native",
          "TypeScript",
          "React Testing Library",
          "REST API",
          "GraphQL",
        ]}
      />
    </Flex>
  );
});
