import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";

export const Divider: React.FC = () => {
  const { colors } = useTheme();

  return (
    <Flex width="100%" height="1px" backgroundColor={colors.text.secondary} />
  );
};
