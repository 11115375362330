export function hexToRgba(hex: string, alpha: number): string {
  const hexWithoutHash = hex.replace(/^#/, "");

  const decimalValues = parseInt(hexWithoutHash, 16);

  const red = (decimalValues >> 16) & 255;
  const green = (decimalValues >> 8) & 255;
  const blue = decimalValues & 255;

  const validAlpha = Math.min(1, Math.max(0, alpha));

  const rgba = `rgba(${red}, ${green}, ${blue}, ${validAlpha})`;

  return rgba;
}
