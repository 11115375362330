import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";

export const ExperienceOverview = React.memo(() => {
  const { textStyles, colors } = useTheme();

  return (
    <Flex
      flexDirection="column"
      width={{ base: "90vw", xl: "25vw", "2xl": "25vw" }}
    >
      <Text {...textStyles.h1} color={colors.text.primary}>
        Experience
      </Text>
      <Text {...textStyles.h2} color={colors.text.primary}>
        Over 1 year experience
      </Text>
      <Text {...textStyles.body} color={colors.text.secondary}>
        Take a stroll back in time to see where I've worked, including my
        experiences and achievements.
      </Text>
    </Flex>
  );
});
