import { Flex } from "@chakra-ui/react";
import React from "react";
import { CardListItem } from "../../../ui";

export const ProjectList = React.memo(() => {
  return (
    <Flex
      flexDirection="column"
      maxWidth={{ base: "90vw", xl: "45vw", "2xl": "35vw" }}
      maxHeight={{ base: undefined, xl: "50vh" }}
      overflowY={{ base: undefined, xl: "scroll" }}
      gap="8"
    >
      <CardListItem
        date="Dec 2023"
        title="Personal Portfolio"
        subtitle="The page you're viewing!"
        description={[
          "Designed and implemented my portfolio to showcase my experience and work.",
          "Hint: You're currently looking at it!",
        ]}
        link="https://luckywest.dev/"
        tags={[
          "TypeScript",
          "React",
          "Chakra",
          "Figma",
          "React Router",
          "Framer Motion",
        ]}
      />
      <CardListItem
        date="Nov 2023"
        title="AnimeZing"
        subtitle="MAL Search Engine"
        description={[
          "A simplified tool to search for anime within MyAnimeList.",
          "Also contains the ability to randomly search for an assortment of recommended anime if you're looking for something new to watch.",
        ]}
        link="https://lw-animezing.netlify.app/"
        tags={["HTML", "CSS", "JavaScript"]}
      />
    </Flex>
  );
});
