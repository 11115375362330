import { createContext, useContext } from "react";

type ScreenBlurContextProps = {
  isScreenBlurred: boolean;
  setIsScreenBlurred: (value: boolean) => void;
};

export const ScreenBlurContext = createContext<
  ScreenBlurContextProps | undefined
>(undefined);

export const useScreenBlurContext = () => {
  const screenBlur = useContext(
    ScreenBlurContext
  ) as unknown as ScreenBlurContextProps;

  if (screenBlur === undefined) {
    throw new Error(
      "useScreenBlurContext was used outside of ScreenBlurContextProvider"
    );
  }

  return screenBlur;
};
