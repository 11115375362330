import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ExperienceScreen, HomeScreen, ProjectScreen } from "../pages";
import { AnimatePresence } from "framer-motion";

export function NavigationRouter() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/experience" element={<ExperienceScreen />} />
        <Route path="/projects" element={<ProjectScreen />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </AnimatePresence>
  );
}
