import React from "react";
import { motion } from "framer-motion";
import { useTheme } from "../../../..";
import { useColorMode } from "@chakra-ui/react";
import { useScreenBlurContext } from "../../../../utils";

export const NoiseBackground: React.FC = () => {
  const { colorMode } = useColorMode();
  const { isScreenBlurred } = useScreenBlurContext();

  const theme = useTheme();
  const particlesRef = React.useRef<HTMLDivElement[]>([]);

  React.useEffect(() => {
    const updateParticles = () => {
      particlesRef.current.forEach((particle) => {
        if (!particle) return;

        const rect = particle.getBoundingClientRect();
        const x = particle.offsetLeft;
        const y = particle.offsetTop;

        const velocityX = 1;
        const velocityY = 1;

        particle.style.left = `${x + velocityX}px`;
        particle.style.top = `${y + velocityY}px`;

        if (
          x + rect.width < 0 ||
          x > window.innerWidth ||
          y + rect.height < 0 ||
          y > window.innerHeight
        ) {
          particle.style.left = `${Math.random() * (window.innerWidth - 10)}px`;
          particle.style.top = `0px`;
        }
      });
    };

    const animationId = requestAnimationFrame(function animate() {
      updateParticles();
      requestAnimationFrame(animate);
    });

    return () => cancelAnimationFrame(animationId);
  }, []);

  const noiseParticles = Array.from({ length: 100 }, (_, index) => (
    <motion.div
      key={index}
      ref={(el) => (particlesRef.current[index] = el as HTMLDivElement)}
      style={{
        width: "3px",
        height: "3px",
        backgroundColor: colorMode === "dark" ? "white" : "black",
        borderRadius: "50%",
        position: "absolute",
        top: `${Math.random() * window.innerWidth}px`,
        left: `${Math.random() * window.innerWidth}px`,
        opacity: 0.3,
        filter: isScreenBlurred ? "blur(4px)" : undefined,
      }}
      animate={{
        x: 0,
        y: Math.random() * 10 - 5,
      }}
      transition={{
        repeat: Infinity,
        duration: 10,
        ease: "linear",
      }}
    />
  ));

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.colors.background.secondary,
        overflow: "hidden",
        zIndex: -2,

        transition: "background-color 0.2s ease",
      }}
    >
      {window.innerWidth >= 992 ? noiseParticles : null}
    </div>
  );
};
