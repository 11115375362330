import React from "react";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { LinkItem } from "../../../ui";
import { useScreenBlurContext } from "../../../../utils";

const ROUTE_NAMES = {
  Home: "/",
  Experience: "/experience",
  Projects: "/projects",
  "Contact me": "/contact",
};

export const HomeLinks = React.memo(() => {
  const [selectedItem, setSelectedItem] = React.useState<string>();
  const navigate = useNavigate();
  const { setIsScreenBlurred } = useScreenBlurContext();

  const onLinkClicked = React.useCallback((routeName: string) => {
    setSelectedItem(routeName);
    navigate(ROUTE_NAMES[routeName as keyof typeof ROUTE_NAMES]);
  }, []);

  const handleContactMe = React.useCallback(() => {
    setIsScreenBlurred(true);
  }, []);

  return (
    <Flex flexDirection="column" gap="2">
      <LinkItem
        selected={selectedItem === "Experience"}
        label="Experience"
        onClick={onLinkClicked}
      />
      <LinkItem
        selected={selectedItem === "Projects"}
        label="Projects"
        onClick={onLinkClicked}
      />
      <LinkItem
        selected={selectedItem === "Contact me"}
        label="Contact me"
        onClick={handleContactMe}
      />
    </Flex>
  );
});
