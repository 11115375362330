import React from "react";
import { useColorMode, IconButton, Flex } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

export const ThemeToggler: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex position="absolute" top={0} right={0} margin="8">
      <IconButton
        aria-label="Toggle dark mode"
        icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
        onClick={toggleColorMode}
        borderRadius={"50%"}
        margin="8px"
      />
    </Flex>
  );
};
