import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";

type HomeLinkItem<T> = {
  selected?: boolean;
  label: T;
  onClick: (value: T) => void;
};

const lineWidth = {
  active: "85px",
  inactive: "48px",
};

function Link<T extends string>({ selected, label, onClick }: HomeLinkItem<T>) {
  const { colors, textStyles } = useTheme();
  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <Flex
      gap="2.5"
      onClick={() => onClick(label)}
      cursor="pointer"
      alignItems="center"
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Flex
        width={selected || isHovering ? lineWidth.active : lineWidth.inactive}
        transition="width 0.5s ease"
        height="1px"
        backgroundColor={
          selected || isHovering ? colors.text.primary : colors.text.secondary
        }
      />
      <Text
        color={
          selected || isHovering ? colors.text.primary : colors.text.secondary
        }
        {...textStyles.body__bold}
      >
        {label}
      </Text>
    </Flex>
  );
}

export const LinkItem = React.memo(Link);
