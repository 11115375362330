import { extendTheme } from "@chakra-ui/react";
import { textStyles } from "./textStyles";

const colors = {
  background: {
    primary: "#000000",
    secondary: "#000000",
  },

  text: {
    primary: "#FFFFFF",
    secondary: "#A3A3A3",
    tertiary: "#FA08B6",
  },
};

const darkThemeValues = {
  colors,
  textStyles,
};

const darkTheme = extendTheme({
  colors,
  textStyles,
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
});

export { darkTheme, darkThemeValues };
