import { Flex } from "@chakra-ui/react";
import { TransitionWrapper } from "../transition-wrapper";
import { PropsWithChildren } from "react";
import { ThemeToggler } from "../theme-toggler";

type ScreenWrapperProps = {
  isFirstScreen: boolean;
} & PropsWithChildren;

export function ScreenWrapper({ children, isFirstScreen }: ScreenWrapperProps) {
  return (
    <TransitionWrapper isFirstScreen={isFirstScreen}>
      <Flex width="100vw" height="100vh">
        <Flex
          flex={1}
          margin={{ base: 0, xl: "80px" }}
          borderRadius={{ base: 0, xl: "40" }}
          padding="8"
          transition="background-color 0.2s ease"
          position="relative"
        >
          <Flex
            flex={1}
            alignItems={{ base: "flex-start", xl: "center" }}
            justifyContent={{ base: "center", xl: undefined }}
          >
            <Flex
              flex={1}
              justifyContent="center"
              alignItems={{ base: "center", xl: "flex-start" }}
              gap={{ base: "2", xl: "16", "2xl": "150px" }}
              flexDirection={{ base: "column", xl: "row" }}
              paddingTop={{ base: "64px", md: undefined }}
              paddingBottom={{ base: "128px", md: undefined }}
              maxWidth={{ base: "782px", xl: "100vw" }}
            >
              {children}
            </Flex>
            <ThemeToggler />
          </Flex>
        </Flex>
      </Flex>
    </TransitionWrapper>
  );
}
