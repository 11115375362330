import { Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";

export const HomeDescription = React.memo(() => {
  const { textStyles, colors } = useTheme();
  return (
    <Flex
      flexDirection="column"
      width={{ base: undefined, xl: "45vw", "2xl": "35vw" }}
      gap="8"
      borderRadius="32px"
    >
      <Text {...textStyles.body} color={colors.text.secondary}>
        Being first exposed to the concept of programming at the age of 11 when
        I was introduced to{" "}
        <Link
          href="https://scratch.mit.edu/"
          isExternal
          color={colors.text.primary}
          {...textStyles.body__bold}
          _hover={{ color: colors.text.tertiary }}
        >
          Scratch
        </Link>
        , I’ve always had a strong interest in the themes of creation and
        problem-solving.
      </Text>
      <Text {...textStyles.body} color={colors.text.secondary}>
        Fast-forwarding to today, I’ve had the privilege of kick-starting my
        career in tech as a software engineer, leading initiatives, working in
        cross-team environments, and accelerating my growth.
      </Text>
      <Text {...textStyles.body} color={colors.text.secondary}>
        Right now, I am working at a fintech company called{" "}
        <Link
          href="https://tipalti.com/"
          isExternal
          color={colors.text.primary}
          {...textStyles.body__bold}
          _hover={{ color: colors.text.tertiary }}
        >
          Tipalti
        </Link>
        . Outside of work, I enjoy singing and producing my own songs, writing
        fiction books, and attempting to keep my colonists alive in{" "}
        <Link
          href="https://store.steampowered.com/app/294100/RimWorld/"
          isExternal
          color={colors.text.primary}
          {...textStyles.body__bold}
          _hover={{
            color: colors.text.tertiary,
            cursor: "url(/rimworld-icon.png) 16 0, auto",
          }}
        >
          Rimworld
        </Link>
        .
      </Text>
    </Flex>
  );
});
