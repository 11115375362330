import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";

export const ProjectOverview = React.memo(() => {
  const { colors, textStyles } = useTheme();

  return (
    <Flex
      flexDirection="column"
      maxWidth={{ base: "90vw", xl: "35vw", "2xl": "20vw" }}
    >
      <Text {...textStyles.h1} color={colors.text.primary}>
        Projects
      </Text>
      <Text {...textStyles.h2} color={colors.text.primary}>
        Fully deployed and available
      </Text>
      <Text {...textStyles.body} color={colors.text.secondary}>
        Curious to see what I've been cooking up in my spare time? Check out the
        list of projects I've developed.
      </Text>
    </Flex>
  );
});
