import { CloseButton, Flex, useColorMode } from "@chakra-ui/react";
import React from "react";
import { hexToRgba, useScreenBlurContext } from "../../../utils";
import { ContactMeDescription, ContactMeLinks } from "../components";
import { useTheme } from "../../..";

export const ContactMeScreen: React.FC = () => {
  const { isScreenBlurred, setIsScreenBlurred } = useScreenBlurContext();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();

  const handleOnClose = () => {
    setIsScreenBlurred(false);
  };

  const isHeightTaller = window.innerHeight > window.innerWidth;

  return (
    <Flex
      opacity={isScreenBlurred ? 1 : 0}
      pointerEvents={isScreenBlurred ? "auto" : "none"}
      width={isHeightTaller ? "40vh" : "40vw"}
      maxWidth="420px"
      maxHeight="420px"
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%,-50%)"
      transition="0.5s"
      zIndex={2}
    >
      <Flex
        flexDirection="column"
        gap="4"
        padding="8"
        position="relative"
        boxShadow={`inset 0px 0px 4px ${hexToRgba(
          colors.text.tertiary,
          0.2
        )}, 0px 0px 12px rgba(0, 0, 0, 0.8)}`}
        backgroundColor={
          colorMode === "dark"
            ? "rgba(255, 255, 255, 0.009)"
            : "rgba(0, 0, 0, 0.05)"
        }
        borderRadius="16px"
        borderTop={
          colorMode === "dark"
            ? "1px rgba(255, 255, 255, 0.1) solid"
            : "1px rgba(0, 0, 0, 0.1) solid"
        }
      >
        <CloseButton
          color={colorMode === "dark" ? "rgba(255, 255, 255)" : "rgba(0, 0, 0)"}
          onClick={handleOnClose}
          position="absolute"
          top={0}
          right={0}
          margin="2"
        />
        <ContactMeDescription />
        <ContactMeLinks />
      </Flex>
    </Flex>
  );
};
