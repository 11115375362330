import { Flex, Link, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../../../..";
import { Tag } from "./Tag";
import { hexToRgba } from "../../../../utils";

type CardListItemProps = {
  title: string;
  subtitle: string;
  date: string;
  description: string[];
  link: string;

  tags?: string[];
};

export const CardListItem = React.memo(
  ({ date, title, subtitle, description, link, tags }: CardListItemProps) => {
    const { textStyles, colors } = useTheme();
    const [isHovering, setIsHovering] = React.useState(false);
    const { colorMode } = useColorMode();

    return (
      <Link
        style={{ textDecoration: "none" }}
        href={link}
        isExternal
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Flex
          flexDirection="row"
          gap="8"
          padding={{ base: 0, xl: "6" }}
          borderRadius="16"
          boxShadow={
            isHovering
              ? `inset 0px 0px 96px ${hexToRgba(colors.text.tertiary, 0.03)}`
              : undefined
          }
          borderTop={
            isHovering
              ? colorMode === "dark"
                ? "1px rgba(255, 255, 255, 0.1) solid"
                : "1px rgba(0, 0, 0, 0.1) solid"
              : `1px ${colors.background.primary} solid`
          }
          backgroundColor={
            isHovering
              ? colorMode === "dark"
                ? "rgba(255, 255, 255, 0.009)"
                : "rgba(0, 0, 0, 0.05)"
              : undefined
          }
          transition="0.2s"
        >
          <Flex
            flex={{ base: 2, xl: 3 }}
            display={{ base: "none", md: "flex" }}
          >
            <Text {...textStyles.body__bold} color={colors.text.secondary}>
              {date}
            </Text>
          </Flex>
          <Flex flex={4} flexDirection="column" gap="1">
            <Flex justifyContent="space-between">
              <Text
                {...{ ...textStyles.body__bold, display: "inline-block" }}
                color={isHovering ? colors.text.tertiary : colors.text.primary}
                transition="0.2s"
              >
                {title}
                <Text
                  as="span"
                  transition="0.5s"
                  marginX={isHovering ? "16px" : "4px"}
                >
                  ⇨
                </Text>
              </Text>
              <Text
                {...textStyles.body__bold}
                color={colors.text.secondary}
                display={{ base: "none", sm: "flex", md: "none" }}
              >
                {date}
              </Text>
            </Flex>
            <Text {...textStyles.body__bold} color={colors.text.secondary}>
              {subtitle}
            </Text>
            <Text
              {...textStyles.body__bold}
              color={colors.text.secondary}
              display={{ base: "flex", sm: "none" }}
            >
              {date}
            </Text>
            {description.map((text) => (
              <Text {...textStyles.body} color={colors.text.secondary}>
                {text}
              </Text>
            ))}
            {tags ? (
              <Flex flexWrap="wrap" gap="8px" marginTop="2">
                {tags.map((tag) => (
                  <Tag tag={tag} />
                ))}
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Link>
    );
  }
);
