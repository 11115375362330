export const textStyles = {
  h1: {
    fontSize: { base: "32px", sm: "32px", md: "38px", "2xl": "48px" },
    fontWeight: "700",
    lineHeight: "160%",
  },
  h2: {
    fontSize: { base: "22px", sm: "22px", md: "20px", "2xl": "24px" },
    fontWeight: "500",
    lineHeight: "150%",
  },
  body: {
    fontSize: { base: "14px", md: "16px" },
    fontWeight: "300",
    lineHeight: "140%",
  },
  body__bold: {
    fontSize: { base: "14px", md: "16px" },
    fontWeight: "700",
    lineHeight: "140%",
  },
};

/*
const breakpoints = {
  base: "0em", // 0px
  sm: "30em", // ~480px. em is a relative unit and is dependant on the font size.
  md: "48em", // ~768px
  lg: "62em", // ~992px
  xl: "80em", // ~1280px
  "2xl": "96em", // ~1536px
};
*/
